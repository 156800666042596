<template>
  <div>
    <div id="biantu" />
  </div>
</template>

<script>
import * as echarts from 'echarts'

var option

export default {
  data() {
    return {}
  },
  computed: {},
  created() {},
  mounted() {
    // var chartDom = (document.getElementById('biantu'),'dark')
     var chartDom = echarts.init(document.getElementById('biantu', 'dark'))
    option = {
      title: {
        // 描述
        // text: 'Basic Radar Chart'
      },
      color: ["#029aff", "#fc9a40"],
      legend: {
        data: ['往年评分', '当前评分'],
         bottom: 0,
         left:20,
      },

      radar: {
        // shape: 'circle',
        indicator: [
          { name: '个人认证', max: 6500 },
          { name: '工作证明', max: 16000 },
          { name: '职称证书', max: 30000 },
          { name: '资格证书', max: 38000 },
          { name: '工作证明', max: 52000 },
          { name: '学历证明', max: 25000 }
        ]
      },


      series: [
        {
          name: '往年评分 vs 当前评分',
          type: 'radar',
          data: [
            {
              value: [4200, 3000, 20000, 35000, 50000, 18000],
              name: '往年评分'
            },
            {
              value: [5000, 14000, 28000, 26000, 42000, 21000],
              name: '当前评分'
            }
          ],

        },
          {
          name: '往年评分 vs 当前评分',
          type: 'radar',
          data: [
            {
              value: [5000, 14000, 28000, 26000, 42000, 21000],
              name: '往年评分'
            }
          ],
          itemStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0, color: '#fc9a40' // 0% 处的颜色

              }],
              global: false // 缺省为 false
            }
          }

        }
      ],
    }

    // option && chartDom.setOption(option)
  },
  methods: {
    budil (option) {
      var chartDom = echarts.init(document.getElementById('biantu', 'dark'))
      chartDom.setOption(option)
    }
  }
}
</script>

<style scoped lang="scss">
#biantu {
  // width: 450px;
  height: 500px;
}
</style>
